<template>
    <b-container class="container">
        <b-row>
            <b-col cols="12" sm="6" class="mb-4 mt-3">
                <h1>Belediye Talepleri</h1>
            </b-col>
            <b-col cols="12" sm="6" class="text-right mt-2 mb-3">
                <date-picker 
                    v-model="range" 
                    type="date" 
                    format="YYYY-MM-DD" 
                    value-type="YYYY-MM-DD"
                    placeholder="Tarih Seçin"
                    range-separator=" / "
                    confirm 
                    range 
                    class="datePicker"
                    ref="datePicker"
                    confirm-text='Tamam'></date-picker>
            </b-col>
        </b-row>
        <b-col>
            <b-row>
                <b-table responsive striped hover :fields="fields" :items="talepler" v-if="talepler">
                    <template v-slot:cell(bel_talep_durum)="durum">
                        <div class="text-right">
                        <b-form-checkbox
                            v-model="durum.item.bel_talep_durum"
                            unchecked-value="0"
                            value="1"
                            @change="check(durum.item.bel_talep_durum, durum.item.bel_talep_id)"
                            name="checkbox[]"></b-form-checkbox>
                        </div>
                    </template>

                    <template v-slot:cell(bel_talep_id)="id">
                        <div>
                            <b-button size="sm" @click="detay(id.item.bel_talep_id)">Adres</b-button>
                        </div>
                    </template>

                    <template v-slot:cell(bel_talep_user)="idUser">
                        <div>
                            <b-form-select
                                :style="{margin: '0!important'}"
                                v-model="idUser.item.bel_talep_toplayici"
                                :options="users" 
                                size="sm" 
                                @change="toplayiciAta(idUser.item.bel_talep_id, $event)"
                                class="mt-3"></b-form-select>
                        </div>
                    </template>
                </b-table>
                <p v-else>Sistemde kayıtlı veri bulunamadı!</p>
            </b-row>
        </b-col>
    </b-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data(){
        return{
            fields : [
                {
                    "key" : "user_adsoyad",
                    "label" : "Belediye",
                    "thStyle": { width: '150px'},
                    "sortable": "true"
                },
                {
                    "key" : "bel_talep_tarih",
                    "label" : "Tarih",
                    "thStyle": { width: '150px'},
                    "sortable": "true"
                },
                {
                    "key" : "bel_talep_adsoyad",
                    "label" : "Ad Soyad",
                    "thStyle": { width: '150px'},
                    "sortable": "true"
                },
                {
                    "key" : "bel_talep_tel",
                    "label" : "Telefon",
                    "thStyle": { width: '150px'}
                },
                {
                    "key" : "bel_talep_miktar",
                    "label" : "Miktar",
                    "thStyle": { width: '150px'}
                },
                // {
                //     "key" : "bel_talep_adres",
                //     "label" : "Adres",
                //     "thStyle": { width: '150px'}
                // },
                {
                    "key" : "bel_talep_id",
                    "label" : "Adres",
                    "thStyle": { width: '150px'}
                },
                {
                    "key" : "bel_talep_user",
                    "label" : "Toplayıcı",
                    "thStyle": { width: '150px'},
                },
                {
                    "key" : "bel_talep_durum",
                    "label" : "Durum",
                    "thStyle": { width: '50px', textAlign: 'right'},
                    "sortable": "true"
                },
            ],
            rangeChange: null,
        }
    },

    mounted(){
        this.$store.dispatch("users");
        this.$store.dispatch("belTalepler", this.days);
    },

    computed: {
        users(){
            // var users = this.$store.state.users.map(c => {
            //     return {
            //         value: c.user_id,
            //         text: c.user_adsoyad
            //     }
            // });
            var users = this.$store.state.users.reduce((item, c) => {
                if(c.user_rutbe == 3){
                    item.push({
                        value: c.user_id,
                        text: c.user_adsoyad
                    })
                }
                return item;
            }, []);
            
            users.unshift({ value: 0, text: "Seçim..."});
            return users;
        },
        talepler(){
            return this.$store.state.belTalepler;
        },
        days(){
            return this.$store.state.days;
        },
        range: {
            get(){
                return this.rangeChange === null ? [this.days.start, this.days.finish] : this.rangeChange;
            },
            set(val){
                this.rangeChange = val        	
            }
        }
    },

    methods: {
        check(a,b){
            var popup = Swal.fire({
                icon: 'info',
                title: "Lütfen Bekleyin.",
                text: "Sunucudan bilgi gelirken lütfen bekleyin.",
                showConfirmButton: false,
            });
            this.$store.dispatch("belTalepEdit", {
                bel_talep_id: b,
                bel_talep_durum: a,
            }).then(() => {
                popup.close();
            });
        },

        detay(id){
            //alert(id);
            var talep = this.talepler.filter(c => c.bel_talep_id == id)[0]
            var mesaj = {
                adsoyad: talep.bel_talep_adsoyad,
                adres: talep.bel_talep_adres
            }
            Swal.fire({
                //icon: 'error',
                title: mesaj.adsoyad,
                text: mesaj.adres,
            })
        },

        toplayiciAta(id, user){
            Swal.fire({
                title: 'Emin Misin?',
                text: "İlgili durum için seçtiğiniz toplayıcı görevlendirilecektir.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, eminim',
                cancelButtonText: 'İptal'
            }).then((result) => {
                if (result.isConfirmed) {
                    var popup = Swal.fire({
                        icon: 'info',
                        title: "Lütfen Bekleyin.",
                        text: "Sunucudan bilgi gelirken lütfen bekleyin.",
                        showConfirmButton: false,
                    });
                    this.$store.dispatch("belTalepAta", {
                        bel_talep_id: id,
                        bel_talep_toplayici: user,
                    }).then(() => {
                        // this.$store.commit("initDurum", {
                        //     status: true,
                        //     message: "Tebrikler işleminiz başarıyla gerçekleştirildi."
                        // });
                        popup.close();
                    });
                }
            });
        }
    },

    watch: {
        rangeChange(a){
            var deger = {
                start: a[0],
                finish: a[1],
            }
            this.veriGuncelle(deger);
        }
    }
}
</script>

<style scoped>
h1{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}
@media (max-width: 767px){
  .datePicker{
    width: 100%;
  }
}
</style>